body {
  background-color: #000;
}

.pane, .view {
  background-color: #f3f3f3 !important;
}

#login-view {
  background: #069;
  background: linear-gradient(to bottom, #0077b3, #046);
  .login-card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    background-image: radial-gradient(closest-side, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0) 100%);
    text-align: center;
    h1 {
      font-size: 20px !important;
    }
    img {
//      box-shadow: 0 0 15px rgba(0, 0, 0, .3);
      border: 1px solid rgba(0, 0, 0, .5);
      max-width: 100px;
    }
  }
  .login-button {
    margin-top: 30px;
    text-align: center;
    button {
      min-width: 100px;
      border-radius: 3px;
      background-color: #006498 !important;
      border-color: #003e5c !important;
      color: rgba(255, 255, 255, .8);
      .icon {
        color: rgba(255, 255, 255, .5);
        margin-right: 5px;
      }
    }
  }
  .item-input {
    border: 1px solid #005782 !important;
    border-right-color: rgba(255, 255, 255, .1) !important;
    border-bottom-color: rgba(255, 255, 255, .1) !important;
    background-color: rgba(255, 255, 255, .2);
    margin-top: 10px;
    border-radius: 5px;
    input {
      color: #000 !important;
      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, .4) !important;
        opacity:  1;
      }
    }
  }
}

.search-form {
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 0 5px #ccc;
  background-color: #fff !important;
  div {
    border: 0 none;
    margin-left: 0;
    margin-right: 0;
    min-height: 44px;
  }
  button {
    margin-bottom: 0 !important;
  }
}

.tabs .icon {
  background-size: contain;
  width: 32px;
  vertical-align: middle !important;
  &.ion-gear-a {
    vertical-align: top !important;
    &::before {
      font-size: 35px !important;
      line-height: 55px !important;
    }
  }
}

.icon-beach {
  background: url(../img/icon-beach.svg) no-repeat 0 0;
}

.icon-yacht {
  background: url(../img/icon-yacht.svg) no-repeat 0 0;
}

.icon-marina {
  background: url(../img/icon-marina.svg) no-repeat 0 0;
}

.q-actions {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  .icon:before {
    font-size: 40px;
  }

  &.q-action-large {
    width: 50%;
  }

  .multiselect {
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    line-height: 28px;
    padding: 10px 38px 10px 10px;
    font-size: .9em;
  }
}

@media (max-width: 768px) {
  .q-actions.q-action-large {
    max-width: 170px;
  }
}

.question-text {
  flex: 1 0;
  -webkit-flex: 1 0;
  input {
    text-align: right;
  }
}

.button-take-photo {
  margin-right: 10px;
  background-color: darken(#0078b0, 5%) !important;
  border: 1px solid darken(#0078b0, 8%) !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  border-radius: 4px;
  text-shadow: 0 0 1px rgba(0, 0, 0, .7);
}

.camera {
  position: absolute;
  top: -35px;
  left: -50px;
}

// Reset field directive
input.reset-field:focus {
  padding-right: 36px;
}

.reset-field-icon {
  position: absolute;
  right: 18px;
  top: 15px;
  margin: 0;
  color: #bbb;
}
// END: Reset field directive

.photos {
  position: absolute;
  bottom: 49px;
  z-index: 100;
  width: 100%;
  height: 150px;
  background-color: darken(#0078b0, 10%);
  box-shadow: 0 0 4px rgba(0, 0, 0, .4);
  padding: 10px;
  img {
    max-height: 130px;
    margin-right: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
  }
}

.has-photos {
  bottom: 199px;
}


.evaluation-list {
  .input-label {
    max-width: none;
  }
}

#storage-marker {
  cursor: pointer;
  font-size: .8em;
  position: absolute;
  bottom: -8px;
  right: 0;
  z-index: 10;
  padding: 10px;
  text-align: center;
  &>div, & i {
    color: rgba(255,255,255,.5);
    transition: color .5s ease;
  }
  i {
    font-size: 20px;
  }
  div:hover, i:hover {
    color: rgba(255, 255, 255, .9);
  }
}

.ng-invalid {
  color: #cc0000 !important;
}

.item-question {
  .input-label {
    display: block;
    white-space: normal;
    max-width: none;
    width: auto;
    margin-right: 165px;
  }

  .q-actions {
    width: 165px;
  }

  &.item-select select {
    left: 0;
    max-width: none;
    width: 100%;
    padding-right: 22px;
  }
}

.settings-card {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  p {
    white-space: normal !important;
  }
}

.ion-looping {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.ion-load-a.ion-spin-animation {
  -webkit-animation-timing-function: steps(8, start);
  -moz-animation-timing-function: steps(8, start);
  animation-timing-function: steps(8, start);
}